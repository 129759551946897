import { useFormik } from "formik"
import React from "react"
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap"
const SignupForm = () => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
    },
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  return (
    <>
      <Form
        action="https://getform.io/f/bc9a96c5-e866-4a6e-bc61-99952ed6adbc"
        method="POST"
        style={{ marginBottom: 20 }}
      >
        <FormGroup>
          <Label for="email">Full Name</Label>
          <Input
            id="name"
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email Address</Label>
          <Input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </FormGroup>
        <FormGroup>
          <Label for="subject">Subject</Label>
          <Input
            id="subject"
            name="subject"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.subject}
            placeholder="Pre-Sales Question, After Sales, General Question"
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleText">Message</Label>
          <Input type="textarea" name="text" id="exampleText" />
        </FormGroup>
        <Button type="submit" color="primary">
          Send
        </Button>
      </Form>
    </>
  )
}

export default SignupForm
