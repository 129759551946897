import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSuitcaseRolling } from "@fortawesome/free-solid-svg-icons"
import ContactForm from "../components/form"
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardSubtitle,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap"
import Layout from "../components/layout"
import Background from "../templates/thailand.jpg"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <Jumbotron
      style={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        marginBottom: 20,
      }}
    >
      <Container
        fluid
        style={{
          backgroundColor: "rgb(124,124,124,0.35)",
          paddingTop: 100,
          paddingBottom: 100,
        }}
      >
        <Container>
          <h1 className="display-3">Contact Us</h1>
          <p className="lead"></p>
        </Container>
      </Container>
    </Jumbotron>
    <Container>
      <Row>
        <Col md={8}>
          <h4>Contact Us Via Email</h4>
          <p>
            Please fill out the contact form below and we will contact you back
            within 48 hours.
          </p>
          <ContactForm />
        </Col>
        <Col md={4}>
          <h4>Other Ways of Contacting Us</h4>
          <p>
            <b>Email:</b>{" "}
            <a href="mailto:info@healthplansthai.com">
              info@healthplansthai.com
            </a>
            <br />
            <b>Thailand Phone Number:</b> 025 660 250
            <br />
            <b>International Number:</b> +66 25 660 250
            <br />
            <b>Mobile Number:</b> 094 896 1727
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SecondPage
